.fondoQuaOneClick {
  background: rgba(0, 0, 0, 0.3) url("../../image/page-title4.jpg");
  background-size: cover;
  background-position: center;
  min-height: 80vh;
  background-blend-mode: darken;
}
.logoQUA {
  width: 18%;
}
.buttonBrochure {
  color: #fff;
  margin-left: 6.3rem;
  margin-top: 1rem;
  padding: .5rem 1rem;
}
.buttonBrochure:hover{
  color: #fff;
  font-weight: 600;
}
.botonText:hover {
  font-weight: 600;
}

.link {
  text-decoration: none;
  color: #fff;
}
.link:hover {
  color: #1886a0;
}
.imagen1 {
  background-image: url("../../image/QUAOC.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 75vh;
}
.imagen2 {
  background-image: url("../../image/photoQUA.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 70vh;
}
.fondoGestiondeCapital {
  background-color: #0d3a6d;
  min-height: 1700px;
}
.containerFondo {
  background-color: #1886a0;
}

@media (max-width: 1024px) {
  .imagen2 {
    height: 65vh;
  }
  .fondoQuaOneClick {
    min-height: 90vh;
  }
  .fondoGestiondeCapital {
    min-height: 1600px;
  }
}

@media (max-width: 576px) {
  .botonDemo {
    display: none;
  }
  .logoQUA {
    width: 40%;
  }
  .buttonBrochure {
    margin-left: .9rem;
  }
  .parrafo01 {
    overflow: hidden !important;
    padding: 35vh 0vh 2vh 5vh !important;
  }
  .parrafo02 {
    overflow: hidden !important;
    font-size: large !important;
    padding: 2vh 0vh 2vh 5vh !important;
  }
  .imagen1,
  .imagen2 {
    display: none;
  }
  .containerItem {
    padding-bottom: 2vh;
    min-width: 100vw !important;
  }
  .fondoGestiondeCapital {
    min-height: 1950px;
  }
}
@media (min-width: 600px) and (max-width: 800px){
  .logoQUA {
    width: 25%;
  }
  .fondoQuaOneClick {
    min-height: 85vh;
  }
  .buttonBrochure {
    margin-left: 1.3rem;
  }
  .parrafo01 {
    font-size: large !important;
    padding-left: 5vw !important;
  }
  .parrafo02 {
    font-size: x-large !important;
    padding-left: 5vw !important;
    padding-right: 10vw !important;
  }
  .fondoGestiondeCapital {
    min-height: 1900px;
  }
}

@media (min-width: 1025px) and (max-width: 1400px) {
  .fondoQuaOneClick {
    min-height: 90vh;
  }
}

@media (min-width: 1440px) {
  .imagen1 {
    min-height: 70vh;
  }
  .imagen2 {
    min-height: 70vh;
  }
  .businessContainer {
    padding-right: 11vh !important;
  }
  .maxContainerImg {
    display: flex;
    max-height: 70vh;
    object-fit: cover;
    object-position: center center;
  }
  .fondoGestiondeCapital {
    min-height: 1600px;
  }
}

@media (min-width: 1900px) {
  .fondoGestiondeCapital {
    min-height: 2300px;
  }
  .fondSize {
    font-size: x-large !important;
  }
}
