.tamanio {
  width: 45%;
  margin-left: 4vh;
  margin-top: 1.7vh;
}
.appBar {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.navBar {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.fondoMenu {
  background-color: brown;
  border: none !important;
  box-shadow: none !important;
}
.link {
  text-decoration: none;
}
.fondoScroll {
  background-color: rgba(0, 0, 0, 0.6) !important;
}
.link:hover {
  color: #fff;
  font-weight: 700 !important;
}
.iconYoutube:hover {
  color: #ff0000;
}
.iconLinkedin:hover {
  color: #0a66c2;
}
.iconInstagram:hover {
  color: #d30050;
}
.iconFacebook:hover {
  color: #3b5998;
}
.linkMenu:hover {
  color: #0a66c2;
  font-weight: 600;
}


@media (max-width: 576px) {
  .tamanio {
    width: 100% !important;
  }
  .ocultar {
    display: none !important;
  }
  .navBar {
    position: absolute !important;
    margin-top: 0 !important;
  }
  .tamanio {
    width: 75%;
    margin-left: 1vh;
    margin-top: 1vh;
  }
  .AppBarMovil {
    justify-content: space-around !important;
  }
  .menuBar {
    flex-direction: row-reverse;
  }
}
