.FondoSumate{
    background-image: url("../image/preFooterMovil.jpg");
    background-position: center;
    background-size: cover;
    min-height: 40vh;
}
.bold{
    font-weight: 600;
}
.link{
    text-decoration: none;
    color: #fff;
    padding-bottom: 4vh;
}
.linkPoliticas{
    text-decoration: none;
    color: #fff;
}
.link:hover{
    color: #fff;
    border-bottom: 1px solid #fff;
    padding: 0;
    margin-bottom: 3.8vh;
}
.fondoAlFinal{
    background-image: url('../image/footer-bg.png');
    min-height: 15vh;
}
.linkTocaAqui{
    text-decoration: none;
}
.linkTocaAqui:hover{
    background-color: #66bb6a;
    border-radius: 10px;
}
.iconYoutube:hover{
    color: #FF0000;
}
.iconLinkedin:hover{
    color: #0A66C2;
}
.iconInstagram:hover{
    color: #D30050;
}
.iconFacebook:hover{
    color:  #3b5998;
}

@media (max-width: 769px) {
    .parrafoSumate{
        text-align: center !important;
        font-size: x-large !important;
        padding-top: 5vh !important;
        padding-bottom: 5vh !important;
    }
    .parrafoAnimate{
        padding-bottom: 10vh;
    }
    .FondoSumate{
        background-position: center !important;
        background-size: cover !important;
    }
    .containerFooterFinal{
        flex-direction: column !important;
        align-items:flex-start !important;
        padding-top: 5vh !important;
        padding-left: 10vw !important;
    }
}