@media (min-width: 1440px){
    .formContainer{
        max-width: 40vw !important;
    }
}
@media (max-width: 800px) {
    .boxForm{
        padding-left: 2vw !important;
        padding-right: 2vw !important;
    }
    .formContainer{
        min-width: 80vw;
        padding: 0px !important;
    }
}
@media (max-width: 576px) {
    .boxForm{
        padding-left: 2vw !important;
        padding-right: 2vw !important;
    }
    .formContainer{
        padding: 0px !important;
    }
}