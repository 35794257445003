.fondoNuestrasAplicaciones{
    background-image: url('../../image/NuestrasAplicaciones.jpg');
    background-size: cover;
    background-position: center;
    min-width: 100vw !important;
    min-height: 75vh;
}
.link{
    text-decoration: none;
    color: #fff;
}
.link:hover{
    color: #1886A0;
}
.buttonHoverCard:hover{
    color: #fff;
}

@media (min-width: 600px) and (max-width: 800px)  {
    .parrafo01{
        font-size:large !important;
        padding-left: 5vw !important;
    }
    .parrafo02{
        font-size:x-large !important;
        padding-left: 5vw !important;
        padding-right: 10vw !important;
    }
    .parrafo04{
        font-size:large !important;
        padding-left: 10vw !important;
        padding-right: 10vw !important;
    }
    .parrafo05{
        padding-left: 2vw !important;
    }
    .containerAplicaciones{
        justify-content: center !important;
        align-items: center !important;
    }
    .cardMovil{
        min-width: 95vw !important;
        min-height: 600px !important;
    }
    .parrafoCard01{
        font-size: larger !important;
        font-weight: 600 !important;
        text-align: center;
    }
    .parrafoCard02{
        padding-left: 1vw;
        padding-right: 1vh;
    }
}

@media (max-width: 576px) {
    .fondoNuestrasAplicaciones{
        min-height: 85vh;
    }
    .parrafo01{
        overflow: hidden !important;
        padding: 35vh 0vh 2vh 5vh !important;
    }
    .parrafo02{
        overflow: hidden !important;
        font-size: large !important;
        padding: 2vh 0vh 2vh 5vh !important;
    }
    .parrafo03{
        overflow: hidden !important;
        text-align: center !important;
        justify-content: center !important;
        padding: 10vh 0vw 2vh 0vw !important;
        margin: 0px !important;
    }
    .parrafo04{
        overflow: hidden !important;
        padding: 2vh 10vw 2vh 10vw !important;
        margin: 0px !important;
        font-size: larger !important;
    }
    .containerAplicaciones{
        overflow: hidden !important;
        justify-content: center !important;
    }
    .cardContainer{
        align-items: center !important;
        justify-content: center !important;
        margin-top: 5vh !important;
        margin-bottom: 5vh !important;
    }
    .card{
        margin-bottom: 2vh !important;
    }
}

@media (min-width: 1900px){
    .cardContainer{
        justify-content: space-around !important;
    }
  }