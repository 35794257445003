.fondoPoliticasSeguridad {
    background: rgba(0, 0, 0, 0.3) url("../../image/page-sobreNosotros.jpg");
    background-size: cover;
    background-position: center;
    min-height: 80vh;
    background-blend-mode: darken;
}
.link {
    text-decoration: none;
    color: #fff;
}
.botonText:hover {
    font-weight: 600;
}

@media (max-width: 576px) {
    .botonDemo {
        display: none;
      }
    .parrafo01 {
      overflow: hidden !important;
      padding: 35vh 0vh 2vh 5vh !important;
    }
  }
