.Mapa {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 110px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.logoportalOC {
  width: 14%;
}

.line {
  display: flex;
  justify-content: center;
  position: relative;
}

.line-dynamics {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  position: relative;
}

.circle-container {
  display: flex;
  gap: 150px;
}

.circle-container.top {
  margin-bottom: 30px;
  position: absolute;
  gap: 350px;
}

.circle-container.bottom {
  gap: 350px;
}

h4 {
  font-weight: 600;
  color: white;
  padding-right: 10px;
}

@media (max-width: 768px) {
  .circle-container {
    gap: 100px;
  }

  .circle-container.top {
    margin-bottom: 20px;
    gap: 100px;
  }
}

@media (max-width: 576px) {
  .logoportalOC {
    width: 30%;
    margin-top: 10px;
  }

  .circle-container.bottom {
    gap: 20px;
  }
}
