.img {
  max-width: 190px;
  background-position: center;
  background-repeat: no-repeat;
  background-size:contain;
  overflow: hidden !important;
}
.img:hover {
  opacity: 0.7;
  max-width: 192px;
  cursor: pointer;
}
.imgItem{
  overflow: hidden !important;
}

.aiCard{
  display:flex;
  border-radius: 6px;
  max-width: 20vw;
}

@media (max-width: 576px) {
.imgItem{
    max-width: 130px;
}
  .img {
    max-width: 120px;
  }
  .img:hover {
    opacity: 0.7;
    max-width: 120px;
  }
}
