.imgHRFactors {
  width: 100%;
  object-fit: cover;
  background-size: contain;
}
.link{
  text-decoration: none;
  border: none !important;
}
.parrafo01Movil {
  animation-duration: 2000ms;
}
.parrafo02Movil {
  animation-duration: 2000ms;
  animation-delay: 1s;
}
.cardMovil1 {
  animation-duration: 2000ms;
  animation-delay: 1s;
}
.cardMovil2 {
  animation-duration: 2000ms;
  animation-delay: 1.5s;
}
.cardMovil3 {
  animation-duration: 2000ms;
  animation-delay: 2s;
}
.Img01 {
  animation-duration: 2000ms;
  animation-delay: 0.1s;
}
.buttonHover:hover {
  color: #016191;
  font-weight: 600;
  background-color: #fff;
  border: 1px solid #016191;
}
.buttonHoverCard:hover {
  color: #fff;
}
.fondoDecisiones {
  background-image: url("../../image/partners-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.fondoServicios {
  background-image: url("../../image/page-title7-1.jpg");
  min-height: 75vh;
}
.fondoConfianNosotros {
  background-image: url("../../image/partners-bg.png");
  min-height: 30vh;
}
.imagenesLogo {
  max-width: 190px;
}
.mapaContacto {
  max-width: 85%;
  object-fit: cover;
  background-size: contain;
}
.botonFlotanteIcon:hover {
  color: #fff;
  background-color: #016c81;
  border-radius: 50%;
}

@media (min-width: 600px) and (max-width: 800px){
  .parrafo01Movil {
    font-size: x-large !important;
  }
  .parrafo02Movil {
    font-size: large !important;
  }
  .cardMovil {
    max-width: 220px !important;
    min-height: 550px !important;
  }
  .parrafoCard01 {
    font-size: larger !important;
    text-align: center;
  }
  .parrafoCard02 {
    padding-left: 1vw;
    padding-right: 1vh;
  }
  .parrafo03Movil {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .containerPrefooter {
    overflow: hidden !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 0px;
  }
  .mapaContacto {
    overflow: hidden !important;
  }
  .formularioContainer {
    overflow: hidden !important;
    padding-left: 0px !important;
    background-color: #ffffff;
    min-width: 85vw;
  }
}

@media (max-width: 576px) {
  .displayNone {
    display: none;
  }
  .parrafo01Movil {
    overflow: hidden !important;
    padding: 7vw 10vw !important;
    font-size: large !important;
  }
  .parrafo02Movil {
    overflow: hidden !important;
    padding: 0 15vw;
    font-size: medium !important;
  }
  .cardContainer1 {
    overflow: hidden !important;
    margin-top: 3vh;
    margin-bottom: 2vh !important;
  }
  .cardMovil {
    max-width: 90vw !important;
    margin-top: 2vh;
  }
  .suiteHRMovil {
    display: flex;
    flex-direction: column !important;
  }
  .gridImgMovil {
    overflow: hidden !important;
    padding: 0px 0vw 0px 0px !important;
    margin: 0px 15vw 0px 0px !important;
  }
  .imgHRFactors {
    overflow: hidden !important;
    display: none;
  }
  .parrafo03Movil {
    overflow: hidden !important;
    text-align: start;
    min-width: 90vw;
    margin-top: 0px !important;
    padding: 0vh 0px 0px 0px !important;
    font-size: x-small;
  }
  .parrafo04Movil {
    overflow: hidden !important;
    font-size: x-large !important;
    padding-top: 5vh !important;
    padding-bottom: 4vh;
  }
  .parrafo05Movil {
    font-weight: 500 !important;
    font-size: medium !important;
    overflow: hidden !important;
    padding-right: 2vw !important;
    padding-left: 9vw !important;
  }
  .suiteHRMovil02 {
    overflow: hidden !important;
    margin-top: 0vh;
  }
  .fondoDecisiones {
    overflow: hidden !important;
    margin-top: 2vh !important;
  }
  .serviciosMovil {
    overflow: hidden !important;
    font-size: x-large;
    text-align: center;
    padding: 5vh;
  }
  .borderBottomMovil {
    overflow: hidden !important;
    border-bottom: 2px solid white;
  }
  .paddinBottomMovil {
    overflow: hidden !important;
    margin-bottom: 5vh;
  }
  .gridMovil {
    overflow: hidden !important;
    padding: 0px !important;
    min-width: 95vw !important;
  }
  .accordionContainer {
    overflow: hidden !important;
    min-width: 100vw !important;
  }
  .parrafo05Movil {
    overflow: hidden !important;
    font-weight: 600;
  }
  .fondoConfianNosotros {
    overflow: hidden !important;
    padding: 0px;
  }
  .parrafo05Movil {
    overflow: hidden !important;
    font-size: large;
  }
  .containerPrefooter {
    overflow: hidden !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    padding: 0px;
  }
  .mapaContacto {
    overflow: hidden !important;
  }
  .formularioContainer {
    overflow: hidden !important;
    padding-left: 0px !important;
    background-color: #ffffff;
    min-width: 85vw;
  }
  .botonFlotante {
    display: none !important;
  }
}

@media (min-width: 1440px) {
  .cardContainer {
    justify-content: space-evenly !important ;
  }
  .cardContainer1 {
    justify-content: space-evenly !important;
  }
  .accordionContainer {
    padding-right: 5vh;
  }
}
