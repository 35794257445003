.fondoCapacitacionesPortada{
    background-image: url('../../image/page-sobreNosotros.jpg');
    background-size: cover;
    background-position: center;
    min-width: 100vw;
    min-height: 75vh;
}
.link{
    text-decoration: none;
    color: #fff;
}
.link:hover{
    color: #1886A0;
}
.fondoCapacitaciones{
    background-color: #0D3A6D;
    min-height: 1970px;
}
.containerImg{
    overflow: hidden !important;
}
.colorK{
    color: #22A249;
}

@media (max-width: 800px) {
    .parrafo01{
        font-size:large !important;
        padding-left: 5vw !important;
    }
    .parrafo02{
        font-size:x-large !important;
        padding-left: 5vw !important;
        padding-right: 10vw !important;
    }
    .parrafo03{
        font-size:x-large !important;
        padding-left: 5vw !important;
        padding-right: 5vw !important;
    }
    .parrafo04{
        font-size:large !important;
        padding-left: 5vw !important;
        padding-right: 5vw !important;
    }
    .containerImg{
        min-width: 50vw!important;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        background-size: cover;
        max-width: 40% !important;
        overflow: hidden !important;
    }
    .cardMovil{
        margin-bottom: 2vh !important;
    }
    .fondoCapacitaciones{
        min-height: 2600px;
    }
}

@media (max-width: 576px) {
    .containerImg{
        display: none;
    }
    .parrafo01{
        overflow: hidden !important;
        padding: 35vh 0vh 2vh 5vh !important;
    }
    .parrafo02{
        overflow: hidden !important;
        font-size: large !important;
        padding: 2vh 0vh 2vh 5vh !important;
    }
    .containerParrafo{
        overflow: hidden !important;
        min-width: 100vw !important;
    }
    .parrafo03{
        overflow: hidden !important;
        font-size: x-large !important;
        padding: 5vh 3vw 0vh 5vh !important;
    }
    .parrafo04{
        overflow: hidden !important;
        font-size: large !important;
        padding: 4vh 3vw 5vh 5vh !important;
    }
    .cardHover{
        margin-bottom: 2vh !important;
    }
    .fondoCapacitaciones{
        min-height: 3600px;
    }
}

@media (min-width: 1900px){
    .fondoCapacitaciones{
        min-height: 2200px;
    }
  }

@media (min-width: 1440px){
    .imagenCapacitaciones{
        width: 115%;
    }
    .containerParrafo{
        padding: 0 15vh;
    }
    .contadorContainer{
        margin-top: 4vh;
    }
    .cardContainer{
        padding-top: 5vh;
    }
    .fondoCapacitaciones{
        min-height: 2150px;
    }
}
@media (min-width: 900px) and (max-width: 1050px){
    .fondoCapacitaciones{
        min-height: 2650px !important;
    }
}
@media (min-width: 1051px) and (max-width: 1230px){
    .fondoCapacitaciones{
        min-height: 2600px !important;
    }
}