.logo {
  margin-bottom: 40vh;
}
.img {
  max-height: 100vh;
  object-fit: cover;
}
.divSlide1 {
  margin-bottom: 20vh;
}
.divSlide2 {
  margin-bottom: 25vh;
}
.logo2 {
  width: 12%;
  margin-bottom: 5vh;
}
.logoSGR {
  width: 40%;
  margin-bottom: 5vh;
}
.parrafo1 {
  padding: 0 15vw;
  font-weight: 700;
}
.disable {
  display: none;
}
.boton {
  border-radius: 50%;
  border: none;
  background-color: transparent;
  color: aliceblue;
  margin-top: 4vh;
}
.botonSGR {
  font-weight: 600;
  color: #fff;
  background: linear-gradient(to bottom right, #003a63, #006364);
  padding: 1.1vh 2.2vw;
}
.botonSGR:hover {
  color: #fff;
  background: linear-gradient(to bottom right, #004f63, #006364);
}
.parrafoSGR {
  font-weight: 600;
}
@media (max-width: 800px) {
  .logo {
    margin-bottom: 25vh;
  }
  .divSlide1 {
    margin-bottom: 15vh;
  }
  .divSlide2 {
    margin-bottom: 15vh;
  }
  .logo2 {
    width: 10%;
    padding-top: 5vh;
    margin-top: 2vh;
    margin-bottom: 0vh;
    padding-bottom: 0vh;
  }
  .parrafo1 {
    font-size: smaller;
    padding: 3vh 0vw 0vh;
    font-weight: 600;
  }
  .boton {
    margin-top: 0vh;
  }
  .logoSGR {
    width: 50%;
    margin-bottom: 2vh;
  }
  .parrafoSGR {
    font-size: smaller;
    font-weight: 600;
  }
  .botonSGR {
    font-size: smaller;
    font-weight: 600;
    margin-top: 2vh;
    padding: 0.9vh 2vw;
  }
}

@media (max-width: 576px) {
  .img {
    min-height: 450px !important;
  }
  .logo {
    margin-bottom: 30vh;
  }
  .divSlide1 {
    margin-bottom: 15vh;
  }
  .divSlide2 {
    margin-bottom: 15vh;
  }
  .logo2 {
    width: 10%;
    padding-top: 5vh;
    margin-top: 2vh;
    margin-bottom: 0vh;
    padding-bottom: 0vh;
  }
  .parrafo1 {
    font-size: smaller;
    padding: 3vh 0vw 0vh;
    font-weight: 600;
  }
  .boton {
    margin-top: 0vh;
  }
  .logoSGR {
    width: 50%;
    margin-bottom: 2vh;
  }
  .parrafoSGR {
    font-size: smaller;
    font-weight: 600;
  }
  .botonSGR {
    font-size: smaller;
    font-weight: 600;
    margin-top: 2vh;
    padding: 0.9vh 2vw;
  }
}
