.fondoSobreNosotros {
  background-image: url("../../image/page-sobreNosotros.jpg");
  background-size: cover;
  background-position: center;
  min-width: 100vw !important;
  min-height: 75vh;
}
.parrafo03 {
  color: #248da5 !important;
  font-weight: 600 !important;
}
.buttonBrochure {
  color: #fff;
  margin-left: 2.2rem;
  margin-top: 1rem;
  padding: 1rem 1.5rem;
  background-color: #81d742;
}
.buttonHoverCard:hover {
  color: #248da5;
}
.cardHover:hover {
  border-top: 2px solid #1786a0;
  border-left: 2px solid #1786a0;
}
.imageParallax {
  width: 90%;
}
.imagenesLogo {
  display: block;
  max-width: 190px;
  object-fit: cover;
  opacity: 1;
}
.imagenesContainer {
  object-fit: cover;
}
.imagenesLogo:hover {
  opacity: 0.7;
  max-width: 192px;
  cursor: pointer;
}
.link {
  text-decoration: none;
  color: #fff;
}
.link:hover {
  color: #1886a0;
}

@media (min-width: 600px) and (max-width: 800px) {
  .parrafo01 {
    font-size: large !important;
    padding-left: 5vw !important;
  }
  .parrafo02 {
    font-size: x-large !important;
    padding-left: 5vw !important;
    padding-right: 10vw !important;
  }
  .parrafo04 {
    font-size: large !important;
    padding-left: 2vw !important;
  }
  .parrafo05 {
    padding-left: 2vw !important;
  }
  .parrafo06 {
    font-size: x-large !important;
  }
  .parrafo07 {
    font-size: large !important;
  }
  .SobreNosotrosContainer {
    margin-top: 5vh !important;
    align-items: flex-start !important;
    overflow: hidden !important;
  }
  .sobreNosotros {
    padding-left: 0vw !important;
    padding-right: 0vw !important;
    overflow: hidden !important;
  }
  .imgPartnerContainer {
    min-width: 50vw !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-size: cover;
    max-width: 40% !important;
    overflow: hidden !important;
  }
  .cardMovil {
    max-width: 220px !important;
    min-height: 550px !important;
  }
  .parrafoCard01 {
    font-size: larger !important;
    font-weight: 600 !important;
    text-align: center;
  }
  .parrafoCard02 {
    padding-left: 1vw;
    padding-right: 1vh;
  }
}

@media (max-width: 576px) {
  .fondoSobreNosotros {
    overflow: hidden !important;
    min-height: 85vh !important;
  }
  .parrafo01 {
    overflow: hidden !important;
    padding: 35vh 0vh 2vh 5vh !important;
  }
  .parrafo02 {
    overflow: hidden !important;
    font-size: large !important;
    padding: 2vh 0vh 2vh 5vh !important;
  }
  .parrafo03 {
    overflow: hidden !important;
    color: #248da5;
    font-weight: 600;
    padding: 0vh 0vw 0vh 5vw !important;
  }
  .parrafo04 {
    overflow: hidden !important;
    font-size: x-large !important;
    padding: 4vh 0vw 0vh 5vw !important;
  }
  .parrafo05 {
    overflow: hidden !important;
    padding: 4vh 25vw 0vh 5vw !important;
  }
  .parrafo06 {
    overflow: hidden !important;
    padding: 5vh 12vw 5vh 4vw !important;
    font-size: x-large !important;
  }
  .parrafo07 {
    overflow: hidden !important;
    padding: 5vh 10vw 0vh 5vw !important;
    text-align: start;
    font-size: large !important;
  }
  .parrafo08{
    padding-right: 6vh !important;
  }
  .containerPeopleSoluciones{
    justify-content: center !important;
  }
  .SobreNosotrosContainer {
    overflow: hidden !important;
    min-width: 99% !important;
    flex-wrap: wrap !important;
    margin-top: 10vh !important;
    flex-direction: column !important;
  }
  .imgPartnerContainer {
    overflow: hidden !important;
    display: none;
  }
  .sobreNosotros {
    overflow: hidden !important;
    min-width: 99% !important;
    padding: 0vh !important;
  }
  .cardContainer {
    overflow: hidden !important;
    margin-top: 0vh !important;
    margin-bottom: 0vh !important;
    margin-left: 6vw !important;
  }
  .cardHover {
    overflow: hidden !important;
    align-items: center !important;
    margin-bottom: 2vh !important;
  }
  .containerPrefooter {
    overflow: hidden !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .acordionItem {
    overflow: hidden !important;
    max-width: 350px !important;
  }
  .imgPrefooter,
  .acordionPrefooter {
    overflow: hidden !important;
    min-width: 99% !important;
    max-width: 400px !important;
  }
  .imagenesContainer {
    padding-right: 10vw !important;
  }
}

@media (min-width: 1440px) {
  .sobreNosotros {
    margin-left: 1vh;
  }
  .imgPartnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cardContainer {
    justify-content: space-around !important;
  }
}
