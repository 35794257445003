.fondoHROneClick {
  background-image: url("../../image/page-title7-1.jpg");
  background-size: cover;
  background-position: center;
  min-height: 80vh;
  background-blend-mode: darken;
}
.logoHR {
  width: 18%;
}

.botonText:hover {
  font-weight: 600;
}

.link {
  text-decoration: none;
  color: #fff;
}
.link:hover {
  color: #1886a0;
}
.imagen1 {
  background-image: url("../../image/service-img.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.imagen2 {
  background-image: url("../../image/business-platform.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh;
}
.fondoGestiondeCapital {
  background-color: #0d3a6d;
  min-height: 1500px;
}
.containerFondo {
  background-color: #1886a0;
}
.buttonBrochure {
  color: #fff;
  margin-left: 6.3rem;
  margin-top: 1rem;
  padding: .5rem 1rem;
}
.buttonBrochure:hover{
  color: #fff;
  font-weight: 600;
}
@media (max-width: 576px) {
  .logoHR {
    width: 40%;
  }
  .botonDemo {
    display: none;
  }
  .buttonBrochure {
    margin-left: .9rem;
  }
  .parrafo01 {
    overflow: hidden !important;
    padding: 35vh 0vh 2vh 5vh !important;
  }
  .parrafo02 {
    overflow: hidden !important;
    font-size: large !important;
    padding: 2vh 0vh 2vh 5vh !important;
  }
  .imagen1,
  .imagen2 {
    display: none;
  }
  .containerItem {
    padding-bottom: 2vh;
    min-width: 100vw !important;
  }
  .fondoGestiondeCapital {
    min-height: 2000px;
  }
}
@media (min-width: 600px) and (max-width: 800px) {
  .fondoHROneClick {
    min-height: 85vh;
  }
  .logoHR {
    width: 25%;
  }
  .buttonBrochure {
    margin-left: 1.3rem;
  }
  .parrafo01 {
    font-size: large !important;
    padding-left: 5vw !important;
  }
  .parrafo02 {
    font-size: x-large !important;
    padding-left: 5vw !important;
    padding-right: 10vw !important;
  }
  .fondoGestiondeCapital {
    min-height: 2100px;
  }
}

@media (width: 1024px) {
  .imagen2 {
    height: 85vh;
  }
  .fondoHROneClick {
    min-height: 90vh;
  }
  .fondoGestiondeCapital {
    min-height: 1700px;
  }
}

@media (min-width: 1025px) and (max-width: 1400px) {
  .fondoHROneClick {
    min-height: 90vh;
  }
}

@media (min-width: 1900px) {
  .container {
    min-height: 60vh;
  }
  .fondSize {
    font-size: x-large;
  }
  .fondoGestiondeCapital {
    min-height: 2100px;
  }
}
